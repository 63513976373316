.page-404 {
    h1 {
        font-size: 28px;
        text-align: center;
        text-transform: uppercase;
        clear: both;
        padding-bottom: 40px;
    }
    p {
        text-align: center;
        font-size: 16px;
    }
}