.experience-and-education {
    &:after {
        content: '';
        clear: both;
        display: block;
    }
    h1, h2 {
        font-size: 28px;
        text-align: center;
        text-transform: uppercase;
        clear: both;
        padding-bottom: 40px;
    }
    h3 {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        padding-bottom: 15px;
    }
    h4 {
        font-size: 22px;
        font-weight: 600;
    }
    h5 {
        font-size: 18px;
    }
    .column-wide {
        width: 50%;
        float: left;
        text-align: right;
        padding-right: 40px;
        border-right: 1px solid #000;

        h2 {
            text-align: right;
        }
    }
    .column-narrow {
        width: 50%;
        float: right;
        padding-left: 40px;

        h2 {
            text-align: left;
        }
    }
    .company {
        padding-bottom: 40px;

        &:last-child {
            padding-bottom: 0;
        }
        ul {
            margin: 15px 0 0 0;

            li {
                padding-bottom: 3px;
            }
        }
    }
    .skills {
        margin-bottom: 45px;

        ul li {
            background-color: #eee;
            margin-bottom: 10px;

            div {
                background-color: #000;
                padding: 3px 7px 4px 7px;
                width: 80%;
                color: #fff;
                text-align: left;
            }
        }
    }
}

@media screen and (max-width: 639px) {
    .experience-and-education {

        .column-wide {
            width: 100%;
            float: none;
            text-align: center;
            padding-right: 0;
            padding-bottom: 40px;
            border-bottom: 1px solid #000;
            margin-bottom: 40px;
            border-right: none;

            h2 {
                text-align: center;
            }
        }
        .column-narrow {
            width: 100%;
            float: none;
            text-align: center;
            padding-left: 0;

            h2 {
                text-align: center;
            }
        }
        .skills {
            border-bottom: 1px solid #000;
            padding-bottom: 40px;
        }
    }
}
