.works {
    margin-top: -100px;

    h1, h2  {
        font-size: 28px;
        text-align: center;
        text-transform: uppercase;
        clear: both;
        padding-bottom: 40px;
    }
    h1 {
        display: inline-block;
        padding-right: 20px;
    }
    ul {
        display: inline-block;

        li {
            display: inline-block;
            margin: 0 20px;
            cursor: pointer;

            &.active {
                border-bottom: 1px solid #000;
            }
            a {
                color: #000;
            }
        }
    }
    .device {
        border: 1px solid #000;
        border-radius: 10px;
        padding: 15px 20px 20px 20px;
        text-align: center;
        position: relative;

        &.tablet {
            width: 800px;
            margin: 0 auto;
        }
        &.mobile {
            width: 400px;
            margin: 40px auto 0 auto;
            padding: 15px 5px 5px 5px;

            .page {
                border-radius: 10px;
            }
            .counter {
                top: -40px;
                right: 80px;
            }
            .pager {
                top: -44px;
                right: 0;
            }
            ul {
                position: absolute;
                top: -37px;
                left: -10px;

                li {
                    margin: 0 10px;
                }
            }
            h2 {
                position: static;
            }
        }

        h2 {
            position: absolute;
            top: 14px;
            left: 20px;
            font-size: 18px;
            padding-bottom: 10px;
        }
        ul {
            padding-bottom: 15px;
        }
        .counter {
            position: absolute;
            top: 14px;
            right: 100px;

            strong {
                font-size: 18px;
                font-weight: 600;
            }
        }
        .pager {
            position: absolute;
            top: 10px;
            right: 20px;

            a {
                cursor: pointer;
                display: inline-block;
                background-color: #000;
                width: 30px;
                height: 30px;
                border-radius: 15px;
                position: relative;
                -webkit-transition: all 0.35s ease-out;
                -moz-transition: all 0.35s ease-out;
                -o-transition: all 0.35s ease-out;
                transition: all 0.35s ease-out;

                &:hover {
                    background-color: #909;
                }
                
                &.next {
                    margin-left: 4px;

                    &:before {
                        transform: rotate(135deg);
                        left: 8px;
                    }
                }
                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 9px;
                    left: 12px;
                    width: 8px;
                    height: 8px;
                    border-top: 3px solid #fff;
                    border-left: 3px solid #fff;
                    transform: rotate(-45deg);
                }
            }
        }
        .page-container {
            overflow: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling:touch;
            width: 100%;
            height: 640px;

            .page {
                width: 100%;
                height: 640px;
                border: 1px solid #000;
            }
        }
    }
}

/* media queries */

@media screen and (max-width: 1023px) {
    .works .device ul {
        visibility: hidden;
    }
}

@media screen and (max-width: 767px) {
    .works h1 {
        display: block;
        padding: 0 0 10px 0;
        text-align: center;
    }
    .works ul {
        text-align: center;
        display: block;
        padding-bottom: 20px;

        li {
            margin-bottom: 5px;
        }
    }
}

@media screen and (max-width: 639px) {
    .works .device {
        padding: 0;
        border-radius: 10px;
        padding-bottom: 7px;

        ul {
            padding-bottom: 25px;
        }

        .page-container {
            height: 480px;
            border-top: 1px solid #000;

            .page {
                border: none;
                height: 480px;
            }
        }
    }
}

@media screen and (max-width: 479px) {
    .works {
        margin: -110px -10px -40px -10px;
        
        .device h2 {
            display: none;
        }
    }
}
