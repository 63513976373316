/* ---------- Global styles ---------- */

html {
	height: 100%;
	font: 14px 'Montserrat', sans-serif;
    font-weight: 400;
	color: #000;
    background-color: #fff;
}

body {    
	height: 100%;
	padding: 0;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
}

a { 
	color: #990099;
	cursor: pointer;
	text-decoration: none;	
	-webkit-transition: all 0.35s ease-out;
	-moz-transition: all 0.35s ease-out;
	-o-transition: all 0.35s ease-out;
	transition: all 0.35s ease-out;
}

input, select, textarea, button {
	font: 14px/140% 'BasierCircleRegular', serif;
	padding: 3px 5px;
	margin: 0;
    border: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
label {
    display: inline-block;
    font-size: 11px;
    line-height: 130%;
    color: #999;
}
main {
    max-width: 1410px;
    padding: 160px 20px 80px 20px;
    margin: 0 auto;
}
.clear:after, header .content:after, footer .content:after {
    content: '';
    clear: both;
    display: block;
}

@media screen and (max-width: 639px) {
    main {
        padding: 140px 20px 80px 20px;
    }
}
