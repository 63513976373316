.hello {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -40%);
    font-size: 9vh;
    width: 5.5em;
    height: 5.5em;
    border-radius: 50%;
    margin: 0 auto;
    background-color: #000;
    color: #fff;
    text-align: center;

    h1 {
        padding: 1em 0 .1em 0;

        &:after {
            content: '.';
            color: #909;
        }
    }
    p {
        font: 30%/150% 'Playfair Display', serif;
    }
    .button {
        display: inline-block;
        color: #000;
        background-color: #fff;
        border: 1px solid #fff;
        font-size: 25%;
        text-transform: uppercase;
        padding: .3em .8em;
        border-radius: 1em;
        margin-top: -20px;
        position: relative;
        top: -.7em;
        -webkit-transition: all 0.35s ease-out;
        -moz-transition: all 0.35s ease-out;
        -o-transition: all 0.35s ease-out;
        transition: all 0.35s ease-out;

        &:hover {
            color: #fff;
            background-color: #000;
        }
    }
}

@media screen and (max-width: 767px) {
    .hello {
        font-size: 14vw;
    }
}