footer {
    width: 100%;
    background-color: #000;
    color: #fff;

    &.fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
    .content {
        max-width: 1410px;
        padding: 30px 20px 20px 20px;
        margin: 0 auto;

        ul {
            float: right;

            li {
                display: inline-block;

                a {
                    display: inline-block;
                    text-transform: uppercase;
                    padding: 0 15px 10px 15px;
                    color: #fff;
                }
                &:first-child a {
                    padding-left: 0;
                }
            }
            &:hover li a {
                opacity: .4;

                &:hover {
                    opacity: 1;
                }
            }
        }
        p {
            float: left;
            opacity: .4;
        }
        .logo {
            float: right;
            height: 54px;
            width: 54px;
            margin: -50px 0 0 20px;
            padding: 14px 10px 0 10px;
            border-radius: 50%;
            background-color: #fff;

            img {
                display: block;
                width: 100%;
                margin-right: 40px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    footer .content {
        padding: 20px;

        p {
            clear: both;
            font-size: 12px;
        }
        .logo {
            margin-top: -40px;
        }
        ul {
            float: left;

            li a {
                padding: 0 10px 10px 10px;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    footer .content .logo {
        display: none;
    }
}

@media screen and (max-width: 639px) {
    footer .content ul li a {
        padding: 0 20px 10px 0;
        color: #fff;
    }
}
