header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 5;

    &.static {
        position: static;
    }
    .content {
        max-width: 1410px;
        padding: 20px;
        margin: 0 auto;
        border-bottom: 1px solid #000;

        .logo img {
            display: block;
            height: 60px;
            margin-right: 30px;
            float: left;
        }
        .hamburger {
            width: 75px;
            padding: 30px 15px;
            cursor: pointer;
            transition: all 0.25s ease;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 5;
            display: none;
        
            .line  {
                width: 24px;
                height: 3px;
                background-color: #000;
                display: block;
                margin: 4px auto;
                -webkit-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
                -webkit-transition-delay: .15s;
                transition-delay: .15s;
            }
        }
        .glass {
            background-color: #000;
            opacity: .75;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 3;
            display: none;
        }
        ul {
            float: left;

            li {
                display: inline-block;

                a {
                    display: inline-block;
                    text-transform: uppercase;
                    padding: 20px 15px;
                    color: #000;
                }
            }
            &:hover li a {
                opacity: .33;

                &:hover {
                    opacity: 1;
                }
            }
        }
        .active {
            .hamburger .line {
                &:nth-child(1) {
                    transform: translateY(7px) rotate(45deg);
                    background-color: #fff;
                }
                &:nth-child(2) {
                    opacity: 0;
                    background-color: #fff;
                }
                &:nth-child(3) {
                    transform: translateY(-7px) rotate(-45deg);
                    background-color: #fff;
                }
            }
            .glass {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    header .content ul li a {
        padding: 20px 10px;
    }
}

@media screen and (max-width: 639px) {
    header .content {
        .logo img {
            display: block;
            margin: 0 auto;
            float: none;
            height: 50px;
        }
        .hamburger {
            display: block;
        }
        ul {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            padding: 15px;
            background-color: #000;
            z-index: 4;

            li {
                display: block;

                a {
                    display: block;
                    padding: 15px 15px;
                    color: #fff;
                }
                &.active a {
                    border: none;
                }
            }
            &:hover li a {
                opacity: 1;
            }
        }
        .active ul {
            display: block;
        }
    }
}
